// Copyright Epic Games, Inc. All Rights Reserved.
import React, { useEffect, useRef, useState } from "react";
import {
  Config,
  AllSettings,
  PixelStreaming,
} from "@epicgames-ps/lib-pixelstreamingfrontend-ue5.2";
import "../App.css";
import StellaAvatar from "./StellaAvatar";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAuth } from "../Authenticator.jsx";
import { MultiStepLoader as Loader } from "./multi-step-loader";

interface ProcessIds {
  serverProcessId?: number;
  playerProcessId?: number;
  httpsPort?: number;
  streamerPort?: number;
  httpPort?: number;
  userID?: string;
  sessionID?: string;
}

export interface PixelStreamingWrapperProps {
  initialSettings?: Partial<AllSettings>;
  selectedStream: Function;
  processIds: ProcessIds;
  stellaReaction: Boolean;
}

export const PixelStreamingWrapper = ({
  initialSettings,
  selectedStream,
  processIds,
  stellaReaction,
}: PixelStreamingWrapperProps) => {
  const videoParent = useRef<HTMLDivElement>(null);
  const [pixelStreaming, setPixelStreaming] = useState<PixelStreaming>();
  const [character, setCharacter] = useState(-1);
  const [clickToPlayVisible, setClickToPlayVisible] = useState(false);
  const { streamingLoader, setStreamingLoader, logoutHandler, userDetails } = useAuth();


  const loadingStates = [
    {
      text: `Hey ${userDetails?.Name}`,
    },
    {
      text: "Travelling in a flight",
    },
    {
      text: "Meeting Tyler Durden",
    },
    {
      text: "He makes soap",
    },
    {
      text: "We goto a bar",
    },
    {
      text: "Start a fight",
    },
    {
      text: "We like it",
    },
    {
      text: "Welcome to F**** C***",
    },
  ];

  useEffect(() => {
    if (videoParent.current) {
      const config = new Config({ initialSettings });
      const streaming = new PixelStreaming(config, {
        videoElementParent: videoParent.current,
      });

      console.log(streaming);
      streaming.addEventListener("playStreamRejected", () => {
        setClickToPlayVisible(true);
      });
      streaming.addResponseEventListener(
        "handle_responses",
        (response: string) => {
          // if (response.includes("StreamActive")) return;
          console.log(response);
          handleUnrealResponse(response, streaming); // Pass streaming as an argument
        }
      );
      setPixelStreaming(streaming);
      streaming.play();
      setClickToPlayVisible(true);
      // Cleanup function
      return () => {
        streaming.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    if (stellaReaction) {
      console.log("Stella Reaction On");

      handleUnrealResponse("StellaReactionOn", pixelStreaming); // Pass streaming as an argument
    }
  }, [stellaReaction]);

  const handleUnrealResponse = (
    response: string,
    stream: PixelStreaming | undefined
  ) => {
    switch (response) {
      case "StellaChatOn":
        console.log("StellaChatOn");

        selectedStream({ speak: false });
        break;
      case "StellaChatClose":
        console.log("StellaChatClose");
        selectedStream({ speak: true });
        break;
      case "StellaReactionOn":
        stream?.emitUIInteraction(
          `{"userid":"${processIds.sessionID}","OnInputMic":"IsSpeaking"}`
        );
        break;
      default:
        break;
    }
  };

  const people = [
    {
      name: "Sam Oliver",
      imageUrl: "../assets/characters/Sam_Oliver.png",
      desc: "Her compassionate approach supports an inclusive space where every step towards self-discovery and emotional health is celebrated.",
      languages: ["English"],
      background: "Latin American ancestry, advocate for gender diversity",
      approach: "Compassionate, intuitive, inclusivity-promoting",
      expertise: "Self-compassion, personal development",
    },
  ];

  function getAspectRatio(width: number, height: number): string {
    function gcd(a: number, b: number): number {
      return b === 0 ? a : gcd(b, a % b);
    }

    const ratio = width / height;

    // Standard aspect ratios
    if (Math.abs(ratio - 16 / 9) < 0.01) return (16 / 9).toFixed(2);
    if (Math.abs(ratio - 4 / 3) < 0.01) return (4 / 3).toFixed(2);

    // Simplify width:height to lowest terms and return decimal format
    const divisor = gcd(width, height);
    const simplifiedWidth = width / divisor;
    const simplifiedHeight = height / divisor;
    const decimalValue = (simplifiedWidth / simplifiedHeight).toFixed(2);

    return decimalValue;
  }


  function getResolution() {
    const width = window.screen.width;
    const height = window.screen.height;
    const resolution = `${width}*${height}`;
    const aspectRatio = getAspectRatio(width, height); // Calculate aspect ratio
    return { resolution, width, height, aspectRatio, }; // Return both resolution and aspect ratio
  }

  function execute(id: number) {
    setTimeout(() => {
      const { resolution, aspectRatio, width, height } = getResolution(); // Destructure to get aspectRatio
      const mode = window.innerWidth > 540 ? "landscape" : "portrait";
      const userid = processIds.sessionID;

      pixelStreaming?.emitUIInteraction(
        `{"userid":"${userid}","character":"${id}","mode":"${mode}","resolution":"${resolution}","width":"${width}","height":"${height}","aspectRatio":"${aspectRatio}"}`
      );
    }, 10000);

    setTimeout(() => {
      selectedStream({
        selected: true,
        name: people[id - 4].name,
        image: people[id - 4].imageUrl,
      });
      setStreamingLoader(false);
      setClickToPlayVisible(false);
    }, 15000);
  }


  return (
    <div className="relative w-full h-full">
      {streamingLoader && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            zIndex: 10000000,
          }}
        >
          <Loader loadingStates={loadingStates} loading={streamingLoader} duration={2000} />
        </div>
      )}
      <div className="object-cover w-full h-full" ref={videoParent} />
      {clickToPlayVisible && (
        <div className="fixed top-0 left-0 z-[10000] w-full h-screen bg-white  ">
          <div className="block sm:hidden bg-gradient-to-br from-[var(--primary-base-gradiant-color)] to-[var(--secondaty-base-gradiant-color)] sm:bg-[var(--primary-base-color)] sm:from-transparent sm:to-transparent  h-[30vh] sm:h-[82px] sm:py-4  flex  sm:justify-center ">
            <img
              src="https://images.unitedwecare.com/odoo/open/logo/AskSamLogo.webp"
              alt=""
              className="brightness-[2000] contrast-100 h-[50px] m-3 sm:m-0   "
            />
            <h2 className="sm:hidden  block text-2xl absolute  top-[15%] text-center font-bold text-white sm:text-[#344054] w-full">
              Choose Your Sam Avatar</h2>
            <button
              className="log-out-button z-[100]"
              onClick={logoutHandler}
            >
              <LogoutIcon />
            </button>
          </div>
          <div className="flex hidden sm:block items-center border-b shadow-sm fixed top-0 w-full bg-white z-50 px-8 py-3">
            <div className="w-full flex justify-between items-center gap-x-4 border-gray-200 bg-white px-4 sm:gap-x-6 sm:px-6 lg:px-0">
              <img
                src="https://images.unitedwecare.com/odoo/open/logo/AskSamLogo.webp"
                alt=""
                className="w-[200px]"
              />
              <button
                className="bg-[var(--primary-base-color-darken)] text-white rounded w-12 h-12"
                onClick={logoutHandler}
              >
                <LogoutIcon />
              </button>
            </div>
          </div>

          <div className="h-[calc(100vh-30vh)] sm:h-[calc(100vh-82px)] overflow-y-scroll w-full flex justify-center items-center  ">
            <div className="p-2 lg:p-10  bg-white m-auto rounded-md lg:rounded-[40px] max-w-7xl lg:shadow-[0px_0px_10px_15px_rgba(0,0,0,0.03)]">
              <h2 className=" text-2xl hidden mb-6 sm:block text-center font-bold text-white sm:text-[#344054] w-full">
                Choose Your Sam Avatar              </h2>
              <div className="flex items-center justify-center">
                {/* <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4   gap-4  "> */}
                {people.map((person, index) => (
                  <div key={person.name}>
                    <StellaAvatar
                      person={person}
                      execute={execute}
                      character={character}
                      setLoader={(item) => setStreamingLoader(item)}
                      setCharacter={(item) => setCharacter(item)}
                      index={index}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
