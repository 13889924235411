import axios from "axios";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

export const Config = () => {
  // Retrieve Authorization token from localStorage
  const authorizationToken = localStorage.getItem("Authorization");

  // Determine X-Email-Id based on profile or default
  const emailId = localStorage.getItem("profile")
    ? JSON.parse(localStorage.getItem("profile")).Emailid
    : "vikas@cyberexpert.com";

  return {
    headers: {
      Authorization: authorizationToken !== null ? authorizationToken : "",
      "X-Email-Id": emailId,
      "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
      "Content-Type": "application/json",
      DeviceType: "web",
      DeviceId: "123",
      Lat: "1",
      Long: "1",
    },
  };
};

export const TokenAPI = async () => {
  const requestOptions = {
    method: "POST",
    redirect: "follow",
  };
  const url = "https://socket.mystella.ai/stella/get-token";
  try {
    const response = await fetch(url, requestOptions);
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const OTPVerification = async (header, body) => {
  const url = process.env.REACT_APP_OTP_ACTION_URL;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: header,
      body: body,
    });
    const responseData = await response.json();
    if (responseData.ResponseCode === 200) {
      return "otp_verified";
    } else if (responseData.ResponseCode === 400) {
      return "otp_not_verified";
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

export const runPixelStreamInstance = async (userid) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}:8000/allocate-port/userId=${userid}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-API-Key": process.env.REACT_APP_X_API_KEY,
        },
      }
    );

    if (!response.ok) {
      throw new Error(
        `Server returned ${response.status} ${response.statusText}`
      );
    }
    const data = await response.json();

    if (data.statusCode === 200) {
      const {
        httpPort,
        httpsPort,
        SFUPort,
        streamerPort,
        serverProcessId,
        playerProcessId,
      } = data?.ports;

      return {
        playerProcessId: playerProcessId,
        serverProcessId: serverProcessId,
        httpsPort: httpsPort,
        streamerPort: streamerPort,
        httpPort: httpPort,
        success: true,
      };
    }

    if (data.statusCode === 204) {
      return {
        success: false,
        message: "No more slots are available please try later.",
      };
    }
  } catch (error) {
    console.error("Error:", error.message);
    return {
      success: false,
      message: "No more slots are available please try later.",
    };
  }
};

export const closePixelStreamInstance = async (tempID) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}:8000/remove-user/userId=${tempID}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "X-API-Key": process.env.REACT_APP_X_API_KEY,
        },
      }
    );

    if (!response.ok) {
      throw new Error(
        `Server returned ${response.status} ${response.statusText}`
      );
    }

    const data = await response.json();
    // console.log("Manual close");

    return { result: "closed" };
  } catch (error) {
    console.error("Error:", error.message);
    return { result: "closed" };
  }
};

export const getCustomDataApiHandler = async (custom_id) => {
  const payload = {
    stella_custom_id: custom_id,
  };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CUSTOM_ID_CHECKER_URL}/new-uwc/dashboard/custom-stella/read`,
      {
        method: "POST",
        headers: {
          "X-API-Key": process.env.REACT_APP_X_API_KEY,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    const responseData = await response.json();
    console.log(responseData);
    return responseData;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const checkDisclaimerStatus = async (userId) => {
  try {
    const response = await fetch(
      `https://integrations.uwc.world/users/${userId}/tnc_status`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error(
        `Server returned ${response.status} ${response.statusText}`
      );
    }

    const contentType = response.headers.get("content-type");
    if (!contentType || !contentType.includes("application/json")) {
      throw new Error("Server did not return JSON");
    }

    const data = await response.json();
    return data?.accepted_terms;
  } catch (error) {
    console.error("Error:", error.message);
    return { message: "some error" };
  }
};

export const acceptDisclaimer = async (userId) => {
  try {
    const response = await fetch(
      `https://integrations.uwc.world/users/${userId}/accept_tnc`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      }
    );

    if (!response.ok) {
      throw new Error(
        `Server returned ${response.status} ${response.statusText}`
      );
    }

    const contentType = response.headers.get("content-type");
    if (!contentType || !contentType.includes("application/json")) {
      throw new Error("Server did not return JSON");
    }

    const data = await response.json();
    return data?.message;
  } catch (error) {
    console.error("Error:", error.message);
    return { message: "some error" };
  }
};

export const fetchSTTWhisper = async (data) => {
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://s2t-v2.uwc.world/transcribe-v2",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Basic YWRtaW46WDlyJDN2ITJ3UXorOHBG",
      },
      data,
      timeout: 15000, // 15 seconds timeout
    };

    const resp = await axios.request(config);

    if (resp?.data?.transcription) {
      return resp.data.transcription;
    } else if (resp?.data?.job_id) {
      // Poll for asynchronous processing if job_id is returned
      const jobId = resp.data.job_id;
      return await pollForTranscription(jobId);
    }
  } catch (error) {
    console.error("Whisper API error", error);
  }
};

const pollForTranscription = async (jobId) => {
  const pollingUrl = `https://s2t-v2.uwc.world/transcribe-v2/${jobId}`;
  let retries = 5;
  while (retries > 0) {
    try {
      const response = await axios.get(pollingUrl, {
        headers: { accept: "application/json" },
      });
      if (response?.data?.status === "completed") {
        return response.data.transcription;
      } else if (response?.data?.status === "processing") {
        retries--;
        await new Promise((res) => setTimeout(res, 3000)); // Wait 3 seconds before retrying
      } else {
        throw new Error("Transcription failed.");
      }
    } catch (error) {
      console.error("Polling error:", error);
      retries--;
    }
  }
  throw new Error("Transcription timed out.");
};

export const GetSessionCountData = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}:8000/show-users`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-API-Key": process.env.REACT_APP_X_API_KEY,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching logs:", error);
  }
};

export const validate_token = async (token) => {
  const resp = await axios.get(
    `${process.env.REACT_APP_BASE_URL}api/sso/validate_token?token=${token}`,
    Config()
  );
  const resp_1 = resp.data;
  if (resp_1.data) resp_1.data = jwtDecode(resp_1.data);
  return resp_1;
};

export const get_waiting_session_loader_video = async (body) => {
  const resp = await axios.post(
    `${process.env.REACT_APP_ODOO_URL}/new-uwc/community/public/feed/?post-type=${body?.post_type}&country=${body?.country}&count=${body?.count}`,
    body,
    Config()
  );
  const resp_1 = resp.data;

  // if (resp_1.data) resp_1.data = jwtDecode(resp_1.data);
  return resp_1;
};

export const fetchConversation = async (userID, body) => {
  try {
    const startTime = new Date().getTime();
    const response = await axios.post(
      `${process.env.REACT_APP_OODO_BASE_URL}stella/conversation/${userID}`,
      body,
      Config()
    );
    const endTime = new Date().getTime();
    const responseTime = endTime - startTime; // Calculate the response time in milliseconds

    //console.log(`Response time: ${responseTime} ms`);
    let decodedData = jwtDecode(response?.data?.data);
    const responseValue = {
      responseTime,
      responseData: decodedData.responses,
    };
    return responseValue;
  } catch (error) {
    console.error("fetchConversation API issue: ", error);
  }
};

export const updateUserIntakeData = async (token, userId, signatureLink) => {
  console.log("isSignatureUploaded", token, userId, signatureLink);
  try {
    const response = await axios.post(
      "https://session-note.uwc.world/stella2/update-user-intake-data",
      {
        user_id: `${userId}`,
        signature_link: signatureLink,
      },
      {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          token: token,
          // authenticationToken:token,
        },
      }
    );
    return response.data; // handle success response
  } catch (error) {
    console.error("Error updating user intake data:", error);
    throw error; // propagate the error
  }
};

export const Odoo_community_get_user_details = async (body) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_ODOO_URL}/new-uwc/get-user-details`,
      body,
      Config()
    );
    return response?.data;
  } catch (error) {
    // return handleError(error);
    console.log(error);
  }
};

export const GetMyDocuments = async (data) => {
  try {
    const headers = {
      Accept: "application/json",
      token: data?.token,
      authenticationToken: Cookies.get("authDataJwt"),
    };

    // Construct the base URL
    let url = `https://session-note.uwc.world/clinicalnotes/list-prescriptions?user_id=${data?.userID}&count=${data?.count}&page=${data?.page}`;

    // Append the category if it is not null
    if (data?.category) {
      url += `&category=${data.category}`;
    }

    //Append the search if it is not empty string
    if (data?.search) {
      url += `&search=${data.search}`;
    }

    const response = await axios.get(url, { headers });

    return response;
  } catch (error) {
    // Handle error here if needed
    console.error("Error fetching documents:", error);
    throw error;
  }
};

export const SendUploadedDoc = async (body) => {
  try {
    const response = await axios.post(
      "https://s2t-v2.uwc.world/process-medical-file",
      body,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Basic YWRtaW46WDlyJDN2ITJ3UXorOHBG",
        },
      }
    );

    return response;
  } catch (error) {
    throw new Error("SendUploadedDoc API error: " + error);
  }
};

export const AddDocument = async (body) => {
  try {
    const response = await axios.post(
      `https://session-note.uwc.world/clinicalnotes/add-prescription`,
      body,
      {
        headers: {
          token: Cookies.get("authToken"),
          // authenticationToken: Cookies.get("authDataJwt"),
          "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
          DeviceType: "web",
          DeviceId: "123",
          Lat: "1",
          Long: "1",
        },
      }
    );

    return response;
  } catch (error) {}
};
